import { useAuthLogout, useAuthUser } from '@/hooks';
import { ICommonComponentProps } from '@/types';
import { gql } from '@apollo/client';
import { User } from '@auth0/auth0-react';
import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHotkeys } from 'react-hotkeys-hook';
import { NewOrgSwitcher } from '@/pages/HomePage/OrganizationPage/Header/UserMenu/NewOrgSwitcher';
import { Icon } from '@/components/primitives/icon/Icon';
import { useAppDispatch, useSuperUser } from '@/redux/hooks';
import { datadogRum } from '@datadog/browser-rum';
import { useHistory } from 'react-router-dom';

interface IUserMenuProps extends ICommonComponentProps {
  user: User;
}

export const UserMenu = ({ className, style, ...rest }: IUserMenuProps) => {
  const { selectedClient } = useSuperUser();
  const { name } = useAuthUser();
  const { t } = useTranslation();
  const { isSuperUser } = useAuthUser();
  const history = useHistory();
  const triggerLogout = useAuthLogout();
  const [newOrgSwitcherVisible, setNewOrgSwitcherVisible] = useState(false);
  const showNewOrgSwitcher = () => setNewOrgSwitcherVisible(true);
  useHotkeys('meta+k', () => {
    datadogRum.addAction('Org. Switcher Shortcut', {
      source: 'settings-and-details',
    });
    showNewOrgSwitcher();
  });

  const dispatch = useAppDispatch();

  const handleOpenAccountSettings = useCallback(() => {
    datadogRum.addAction('Open Account Settings', {
      source: 'settings-and-details',
    });
    history.push('/settings/tags');
  }, [dispatch]);
  const displayName = (selectedClient && Object.keys(selectedClient)[0]) || name;
  return (
    <>
      <Menu
        as="div"
        className={clsx('relative z-50 inline-block text-left mr-4', className)}
        style={style}
        {...rest}
      >
        {({ open }) => (
          <>
            <Menu.Button
              data-id="userMenuButton"
              className="flex flex-row items-center rounded-full border border-gray-100 text-sm hover:border-white w-[237px]"
            >
              <div className="flex h-8 items-center w-full flex-row justify-between px-2 text-white">
                <span className="text-md ">{displayName}</span>
                <Icon
                  name="down"
                  size={16}
                  className={`mr-1.5 transition-transform ${open ? 'rotate-180' : 'rotate-0'}`}
                />
              </div>
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="focus:outline-none absolute mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 w-[168px]">
                <div className="px-1 py-1 ">
                  {isSuperUser && (
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          data-id="switchOrganizationButton"
                          className={clsx(
                            'group flex w-full items-center rounded-md px-2 py-2 text-sm gap-2',
                            active ? 'bg-gray-100' : 'bg-white',
                          )}
                          onClick={showNewOrgSwitcher}
                        >
                          <span className="flex items-center overflow-hidden">
                            <Icon name="switch" size={16} className="mr-2 flex-shrink-0" />
                            <span className="truncate">{t('switchOrganization')}</span>
                          </span>
                        </button>
                      )}
                    </Menu.Item>
                  )}
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        data-id="settings"
                        className={clsx(
                          'group flex w-full items-center rounded-md px-2 py-2 text-sm gap-2',
                          active ? 'bg-gray-100' : 'bg-white',
                        )}
                        onClick={handleOpenAccountSettings}
                      >
                        <span className="flex items-center overflow-hidden">
                          <Icon name="gear" size={16} className="mr-2 flex-shrink-0" />
                          <span className="truncate">Account Settings</span>
                        </span>
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="http://support.butlr.io/tickets"
                        data-id="contactSupportButton"
                        className={clsx(
                          active ? 'bg-gray-100' : 'bg-white',
                          'group flex w-full items-center rounded-md px-2 py-2 text-sm mt-auto',
                        )}
                        onClick={() => {
                          datadogRum.addAction('Contact Support Clicked', {
                            source: 'settings-and-details',
                          });
                        }}
                      >
                        <span className="flex items-center">
                          <Icon name="chat" size={16} className="mr-2" />
                          {t('customerSupport')}
                        </span>
                      </a>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <a
                        className={clsx(
                          active ? 'bg-gray-100' : 'bg-white',
                          'group flex w-full items-center rounded-md px-2 py-2 text-sm mt-auto',
                        )}
                        target="_blank"
                        rel="noreferrer"
                        href="https://docs.butlr.io"
                        onClick={() => {
                          datadogRum.addAction('API Docs Clicked', {
                            source: 'settings-and-details',
                          });
                        }}
                      >
                        <Icon name="code" size={16} className="mr-2" /> {t('apiDoc')}
                      </a>
                    )}
                  </Menu.Item>
                  <div className="my-1 mx-2 border-b border-gray-200"></div>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        data-id="logoutButton"
                        className={clsx(
                          'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                          active ? 'bg-product-red500 text-white' : 'text-product-red500 bg-white',
                          'transition-colors duration-150 ease-in-out',
                        )}
                        onClick={() => {
                          datadogRum.addAction('Logout Triggered', {
                            source: 'user-action',
                          });
                          triggerLogout();
                        }}
                      >
                        <Icon name="people" size={16} className="mr-2" /> {t('logOut')}
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
      {isSuperUser && (
        <NewOrgSwitcher open={newOrgSwitcherVisible} onOpenChange={setNewOrgSwitcherVisible} />
      )}
    </>
  );
};

UserMenu.fragments = {
  Floor: gql`
    fragment UserMenuFloor on Floor {
      id: floor_id
      timezone
    }
  `,
};
