import { HiveInfoUnplottedSensorFragment } from '@/.generated/graphql';
import { useAppSelector } from '@/redux/hooks';
import { ESensorMode, TInDirection, TXYPoint, ESensorModel } from '@/types';
import { datadogRum } from '@datadog/browser-rum';
import { createSlice } from '@reduxjs/toolkit';
import { omit, pickBy } from 'lodash';
import { calculateSensorCoverage, getRectRotation, rectToPoints } from '../../utils/utils';
import type { FloorPlan, Hive, Room, Sensor, Zone } from '../useGetState';
import type { SaveResult } from '../types';
import { SensorObject, HiveObject, RoomObject, FloorPlanObject } from '../../sdk';
import { generateId } from '@/utils/ids';

/** Extend floor plan with additional properties not currently saved on the backend. */
export type AugmentedFloorPlan = FloorPlan & {
  opacity: number;
};

export type CoreReducerState = {
  base: {
    sensors: Sensor[];
    rooms: Room[];
    zones: Zone[];
    hives: Hive[];
    unplotted: Hive[];
    floorplans: FloorPlan[];
  };
  modified: {
    sensors: Record<string, Partial<Sensor>>;
    rooms: Record<string, Partial<Room>>;
    zones: Record<string, Partial<Zone>>;
    hives: Record<string, Partial<Hive>>;
    floorplans: Record<string, Partial<AugmentedFloorPlan>>;
  };
  deleted: string[];
  added: {
    sensors: Sensor[];
    rooms: Room[];
    zones: Zone[];
    hives: Hive[];
    floorplans: AugmentedFloorPlan[];
  };
  selected: Array<string>;
  // The selectedHovered state is used when we want to show hover state of selected items but can't not trigger their mouse event
  selectedHovered: boolean;
  errors: {
    [id: string]: string;
  };
  search: {
    text: string;
    hoverItemId: string | null;
  };
  layers: LayerReducerState;
  initialized: boolean;
  highlightRelatedDevices: boolean;
};

export type LayerState = { visible: boolean; locked: boolean; subLayers?: string[] };
export type LayerReducerState = {
  sensors: LayerState;
  rooms: LayerState;
  zones: LayerState;
  hives: LayerState;
  floorplans: LayerState;
  heatmaps: LayerState;
  detections: LayerState;
  sensorModel: LayerState;
};
export type LayerName = keyof LayerReducerState;

export type StudioReducerState = CoreReducerState & {
  undoState: CoreReducerState[];
  redoState: CoreReducerState[];
};

// a function just reduces the risk of accidentally reusing the same object...
export function getEmptyState(): StudioReducerState {
  return {
    initialized: false,
    highlightRelatedDevices: false,
    undoState: [],
    redoState: [],
    base: {
      sensors: [],
      rooms: [],
      zones: [],
      hives: [],
      unplotted: [],
      floorplans: [],
    },
    modified: {
      sensors: {},
      rooms: {},
      zones: {},
      hives: {},
      floorplans: {},
    },
    added: {
      sensors: [],
      rooms: [],
      zones: [],
      hives: [],
      floorplans: [],
    },
    deleted: [],
    selectedHovered: false,
    selected: [],
    errors: {},
    layers: {
      sensors: {
        visible: true,
        locked: false,
      },
      rooms: {
        visible: true,
        locked: false,
      },
      zones: {
        visible: true,
        locked: false,
      },
      hives: {
        visible: true,
        locked: false,
      },
      floorplans: {
        visible: true,
        locked: true,
      },
      heatmaps: {
        visible: false,
        locked: false,
      },
      detections: {
        visible: true,
        locked: false,
      },
      sensorModel: {
        visible: false,
        locked: false, // This won't be used
      },
    },
    search: {
      text: '',
      hoverItemId: null,
    },
  };
}

const historyCapacity = 20;

/**
 * Given the previous state, updates the undo state and clears the redo state.
 */
function updateHistory({
  undoState,

  redoState,
  ...prevState
}: StudioReducerState): StudioReducerState {
  return {
    ...prevState,
    redoState: [],
    undoState: [...undoState, prevState].slice(-historyCapacity),
  };
}

/**
 * Updates selected items using updater functions that return the
 * new modified state to be merged with the existing modified state.
 */
function updateSelected({
  prevState,
  sensorUpdateFunc,
  roomUpdateFunc,
  zoneUpdateFunc,
  hiveUpdateFunc,
  floorplanUpdateFunc,
}: {
  prevState: StudioReducerState;
  sensorUpdateFunc?: (s: Sensor) => Partial<Sensor>;
  roomUpdateFunc?: (r: Room) => Partial<Room>;
  zoneUpdateFunc?: (z: Zone) => Partial<Zone>;
  hiveUpdateFunc?: (h: Hive) => Partial<Hive>;
  floorplanUpdateFunc?: (f: AugmentedFloorPlan) => Partial<AugmentedFloorPlan>;
}): StudioReducerState {
  const { selected, base, added, modified } = prevState;
  const selectedSensors = selected
    .map((id) => {
      const sensor = [...base.sensors, ...added.sensors].find((s) => s.id === id);
      return sensor ? { ...sensor, ...modified.sensors[id] } : null;
    })
    .filter((s): s is Sensor => !!s);
  const selectedRooms = selected
    .map((id) => {
      const room = [...base.rooms, ...added.rooms].find((r) => r.id === id);
      return room ? { ...room, ...modified.rooms[id] } : null;
    })
    .filter((r): r is Room => !!r);
  const selectedZones = selected
    .map((id) => {
      const zone = [...base.zones, ...added.zones].find((z) => z.id === id);
      return zone ? { ...zone, ...modified.zones[id] } : null;
    })
    .filter((z): z is Zone => !!z);
  const selectedHives = selected
    .map((id) => {
      const hive = [...base.hives, ...added.hives].find((h) => h.id === id);
      return hive ? { ...hive, ...modified.hives[id] } : null;
    })
    .filter((h): h is Hive => !!h);
  const selectedFloorplans = selected
    .map((id) => {
      const floorplan = [...base.floorplans, ...added.floorplans].find((f) => f.id === id);
      return floorplan ? { ...floorplan, ...modified.floorplans[id] } : null;
    })
    .filter((f): f is AugmentedFloorPlan => !!f);
  return {
    ...prevState,
    modified: {
      ...prevState.modified,
      sensors: {
        ...modified.sensors,
        ...(sensorUpdateFunc &&
          Object.fromEntries(
            selectedSensors.map((s) => [
              s.id,
              { ...modified.sensors[s.id], ...sensorUpdateFunc(s) },
            ]),
          )),
      },
      rooms: {
        ...modified.rooms,
        ...(roomUpdateFunc &&
          Object.fromEntries(
            selectedRooms.map((r) => [r.id, { ...modified.rooms[r.id], ...roomUpdateFunc(r) }]),
          )),
      },
      zones: {
        ...modified.zones,
        ...(zoneUpdateFunc &&
          Object.fromEntries(
            selectedZones.map((z) => [z.id, { ...modified.zones[z.id], ...zoneUpdateFunc(z) }]),
          )),
      },
      hives: {
        ...modified.hives,
        ...(hiveUpdateFunc &&
          Object.fromEntries(
            selectedHives.map((h) => [h.id, { ...modified.hives[h.id], ...hiveUpdateFunc(h) }]),
          )),
      },
      floorplans: {
        ...modified.floorplans,
        ...(floorplanUpdateFunc &&
          Object.fromEntries(
            selectedFloorplans.map((f) => [
              f.id,
              { ...modified.floorplans[f.id], ...floorplanUpdateFunc(f) },
            ]),
          )),
      },
    },
  };
}

const studioSlice = createSlice({
  initialState: getEmptyState(),
  name: 'studio',
  reducers: {
    select: (prevState, action: { payload: { id: string } }) => {
      const { id } = action.payload;
      return { ...prevState, selected: [id] };
    },
    multiSelect: (prevState, action: { payload: { ids: string[] } }) => {
      const { ids } = action.payload;
      return { ...prevState, selected: ids };
    },
    deselect: (prevState, action: { payload: { id: string } }) => {
      const { id } = action.payload;
      const selected = new Set<string>(prevState.selected);
      selected.delete(id);
      return { ...prevState, selected: Array.from(selected) };
    },
    toggleSelect: (prevState, action: { payload: { id: string } }) => {
      const { id } = action.payload;
      const selected = new Set<string>(prevState.selected);
      if (selected.has(id)) {
        selected.delete(id);
      } else {
        selected.add(id);
      }
      return { ...prevState, selected: Array.from(selected) };
    },
    appendSelect: (prevState, action: { payload: { ids: string[] } }) => {
      const { ids } = action.payload;
      const selected = new Set<string>([...prevState.selected, ...ids]);
      return { ...prevState, selected: Array.from(selected) };
    },
    deselectAll: (prevState) => {
      return { ...prevState, selected: [] };
    },
    move: (prevState, action: { payload: { x: number; y: number } }) => {
      const { x, y } = action.payload;
      return updateSelected({
        prevState: updateHistory(prevState),
        sensorUpdateFunc: (s) => {
          const { center } = SensorObject.fromSensor(s).translate(x, y);
          return {
            center,
          };
        },
        roomUpdateFunc: (r) => {
          const { coordinates } = RoomObject.fromRoom(r).translate(x, y);
          return {
            coordinates,
          };
        },
        zoneUpdateFunc: (z) => {
          const { coordinates } = RoomObject.fromRoom(z).translate(x, y);
          return {
            coordinates,
          };
        },
        hiveUpdateFunc: (h) => {
          const { center } = HiveObject.fromHive(h).translate(x, y);
          return {
            coordinates: center,
          };
        },
        floorplanUpdateFunc: (f) => {
          const { coordinates } = FloorPlanObject.fromFloorPlan(f).translate(x, y);
          return {
            coordinates,
          };
        },
      });
    },
    resize: (
      prevState,
      action: { payload: { sx: number; sy: number; anchor: TXYPoint; rotation: number } },
    ) => {
      const { sx, sy, anchor, rotation } = action.payload;
      return updateSelected({
        prevState: updateHistory(prevState),
        roomUpdateFunc: (r) => {
          const { coordinates } = RoomObject.fromRoom(r).scale(sx, sy, anchor, rotation);
          return {
            coordinates,
          };
        },
        zoneUpdateFunc: (z) => {
          const { coordinates } = RoomObject.fromRoom(z).scale(sx, sy, anchor, rotation);
          return {
            coordinates,
          };
        },
        floorplanUpdateFunc: (f) => {
          const { coordinates } = FloorPlanObject.fromFloorPlan(f).scale(sx, sy, anchor, rotation);
          return {
            coordinates,
          };
        },
      });
    },
    rotate: (prevState, action: { payload: { angle: number; origin: TXYPoint } }) => {
      const { angle, origin } = action.payload;
      return updateSelected({
        prevState: updateHistory(prevState),
        sensorUpdateFunc: (s) => {
          const { center, rotation } = SensorObject.fromSensor(s).rotate(angle, origin);
          return {
            center,
            orientation: [s.orientation[0], rotation, s.orientation[2]],
          };
        },
        hiveUpdateFunc: (h) => {
          const { center } = HiveObject.fromHive(h).rotate(angle, origin);
          return {
            coordinates: center,
          };
        },
        roomUpdateFunc: (r) => {
          const { coordinates, rotation } = RoomObject.fromRoom(r).rotate(angle, origin);
          return {
            coordinates,
            rotation,
          };
        },
        zoneUpdateFunc: (z) => {
          const { coordinates, rotation } = RoomObject.fromRoom(z).rotate(angle, origin);
          return {
            coordinates,
            rotation,
          };
        },
        floorplanUpdateFunc: (f) => {
          const { coordinates, rotation } = FloorPlanObject.fromFloorPlan(f).rotate(angle, origin);
          return {
            coordinates,
            rotation,
          };
        },
      });
    },
    addSensor: (prevState, action: { payload: { x: number; y: number } }) => {
      const { x, y } = action.payload;
      const {
        added: { sensors },
      } = prevState;
      const sensorId = generateId.sensor();
      const totalSensors = prevState.base.sensors.length + prevState.added.sensors.length;
      return {
        ...updateHistory(prevState),
        selected: [sensorId],
        added: {
          ...prevState.added,
          sensors: [
            ...sensors,
            {
              name: `Sensor ${totalSensors + 1}`,
              id: sensorId,
              center: [x, y],
              mode: ESensorMode.PRESENCE,
              macAddress: '',
              orientation: [0, 0, 0],
              fov: 90,
              height: 3,
              model: ESensorModel.UNKNOWN,
            },
          ],
        },
      };
    },
    bulkAddSensors: (
      prevState,
      action: { payload: { x: number; y: number; sensors: HiveInfoUnplottedSensorFragment[] } },
    ) => {
      const { x, y, sensors } = action.payload;
      const {
        base,
        modified,
        deleted,
        added: { sensors: prevSensors },
      } = prevState;

      const defaultHeight = 3;
      const defaultFov = 90;
      const totalSensors = prevState.base.sensors.length + prevState.added.sensors.length;
      const newSensors = sensors
        .filter(({ id }) => !base.sensors.find((s) => s.id === id))
        .map(({ macAddress, model, mode, hive, id }, index) => {
          return {
            name: `Sensor ${totalSensors + 1 + index}`,
            id: id || generateId.sensor(),
            center: [
              x + index * 1.2 * calculateSensorCoverage({ height: defaultHeight, fov: defaultFov }),
              y,
            ] as TXYPoint,
            mode: (mode as ESensorMode) || ESensorMode.PRESENCE,
            macAddress: macAddress || '',
            orientation: [0, 0, 0] as [number, number, number],
            fov: defaultFov,
            height: defaultHeight,
            parallelToDoor: mode === ESensorMode.TRAFFIC ? true : undefined,
            inDirection: mode === ESensorMode.TRAFFIC ? (1 as TInDirection) : undefined,
            doorLine: mode === ESensorMode.TRAFFIC ? 0.15 : undefined,
            model: model ? (model as ESensorModel) : ESensorModel.UNKNOWN,
            hive: hive ?? undefined,
          };
        });

      const modifiedSensors = sensors
        .filter(({ id }) => base.sensors.find((s) => s.id === id))
        .map(({ id = generateId.sensor(), height = defaultHeight, fov = defaultFov }, index) => ({
          id,
          center: [
            x + (index + newSensors.length) * 1.2 * calculateSensorCoverage({ height, fov }),
            y,
          ] as TXYPoint,
        }));

      const sensorIds = [...newSensors.map((s) => s.id), ...modifiedSensors.map((s) => s.id)];
      return {
        ...updateHistory(prevState),
        selected: Array.from(new Set<string>(sensorIds)),
        modified: {
          ...modified,
          sensors: modifiedSensors.reduce(
            (acc, { id, ...rest }) => ({ ...acc, [id]: rest }),
            modified.sensors,
          ),
        },
        added: {
          ...prevState.added,
          sensors: [...prevSensors, ...newSensors],
        },
        // We need to remove the modified sensor from deleted list
        deleted: deleted.filter((id) => !modifiedSensors.find((s) => s.id === id)),
      };
    },
    addFloorPlan: (prevState, action: { payload: { base64: string; aspectRatio: number } }) => {
      const { base64, aspectRatio } = action.payload;
      const {
        added: { floorplans },
      } = prevState;
      const localFloorplanId = generateId.floorplan();
      const width = 50;
      const height = width / (aspectRatio || 1);
      return {
        ...updateHistory(prevState),
        selected: [localFloorplanId],
        added: {
          ...prevState.added,
          floorplans: [
            ...floorplans,
            {
              name: 'New floorplan',
              id: localFloorplanId,
              base64: base64,
              url: '',
              aspectRatioLocked: true,
              opacity: 1,
              coordinates: rectToPoints(0, 0, width, height),
              rotation: 0,
            },
          ],
        },
      };
    },
    addRoom: (
      prevState,
      action: { payload: { x: number; y: number; width: number; height: number } },
    ) => {
      const { x, y, width, height } = action.payload;
      const {
        added: { rooms },
      } = prevState;
      const roomId = generateId.room();
      const totalRooms = prevState.base.rooms.length + prevState.added.rooms.length;
      return {
        ...updateHistory(prevState),
        selected: [roomId],
        added: {
          ...prevState.added,
          rooms: [
            ...rooms,
            {
              name: `Room ${totalRooms + 1}`,
              id: roomId,
              coordinates: rectToPoints(x, y, width, height),
              capacity: {
                max: 0,
                mid: 0,
              },
              rotation: 0,
              sensors: [],
            },
          ],
        },
      };
    },
    modifySensors: (
      prevState,
      action: {
        payload: {
          data: (Partial<Sensor> & { id: string })[];
          skipUpdateHistory?: boolean;
        };
      },
    ) => {
      datadogRum.addAction('Modify sensors', { source: 'studio-on-web' });
      const {
        payload: { data: modifiedSensors, skipUpdateHistory },
      } = action;
      const {
        modified: { sensors },
      } = prevState;
      return {
        ...(skipUpdateHistory ? prevState : updateHistory(prevState)),
        modified: {
          ...prevState.modified,
          sensors: {
            ...sensors,
            ...Object.fromEntries(
              modifiedSensors.map((s) => [
                s.id,
                { ...sensors[s.id], ...omit(s, 'distanceToRoomBorder') },
              ]),
            ),
          },
        },
      };
    },
    modifyRooms: (prevState, action: { payload: (Partial<Room> & { id: string })[] }) => {
      datadogRum.addAction('Modify rooms', { source: 'studio-on-web' });

      const { payload: modifiedRooms } = action;
      const {
        modified: { rooms },
      } = prevState;
      return {
        ...updateHistory(prevState),
        modified: {
          ...prevState.modified,
          rooms: {
            ...rooms,
            ...Object.fromEntries(modifiedRooms.map((r) => [r.id, { ...rooms[r.id], ...r }])),
          },
        },
      };
    },
    modifyFloorPlan: (
      prevState,
      action: {
        payload: {
          data: Partial<AugmentedFloorPlan> & { id: string };
          skipUpdateHistory?: boolean;
        };
      },
    ) => {
      datadogRum.addAction('Modify floor plan', { source: 'studio-on-web' });

      const {
        payload: { data, skipUpdateHistory },
      } = action;
      const {
        modified: { floorplans },
      } = prevState;
      return {
        ...(skipUpdateHistory ? prevState : updateHistory(prevState)),
        modified: {
          ...prevState.modified,
          floorplans: {
            ...floorplans,
            [data.id]: { ...floorplans[data.id], ...data },
          },
        },
      };
    },
    addZone: (
      prevState,
      action: {
        payload: {
          x: number;
          y: number;
          width: number;
          height: number;
          roomId?: string;
          name?: string;
        };
      },
    ) => {
      const { x, y, width, height, roomId } = action.payload;
      const {
        added: { zones },
      } = prevState;
      const zoneId = generateId.zone();
      const totalZones = prevState.base.zones.length + prevState.added.zones.length;
      return {
        ...updateHistory(prevState),
        selected: [zoneId],
        added: {
          ...prevState.added,
          zones: [
            ...zones,
            {
              name: `Zone ${totalZones + 1}`,
              room_id: roomId,
              id: zoneId,
              coordinates: rectToPoints(x, y, width, height),
              rotation: 0,
              capacity: {
                max: 0,
                mid: 0,
              },
            },
          ],
        },
      };
    },
    modifyZones: (prevState, action: { payload: Partial<Zone> & { id: string } }) => {
      datadogRum.addAction('Modify zones', { source: 'studio-on-web' });

      const { payload } = action;
      const id = payload.id;
      const {
        modified: { zones },
      } = prevState;
      return {
        ...updateHistory(prevState),
        modified: {
          ...prevState.modified,
          zones: {
            ...zones,
            [id]: { ...zones[id], ...payload },
          },
        },
      };
    },
    addHive: (prevState, action: { payload: { x: number; y: number } }) => {
      const { x, y } = action.payload;
      const {
        base,
        added: { hives },
      } = prevState;
      // try to find an unplotted hive and use that first
      const stagedHive = base.unplotted.find((a) => !hives.some((b) => b.id === a.id));
      if (stagedHive) {
        const hiveId: string = stagedHive.id;
        return {
          ...updateHistory(prevState),
          selected: [hiveId],
          added: {
            ...prevState.added,
            hives: [
              ...hives,
              {
                name: stagedHive.name,
                id: hiveId,
                coordinates: [x, y],
                serial_number: stagedHive.serial_number,
                sensors: [],
                connectionHealth: {
                  status: '',
                  codes: [],
                },
                note: stagedHive.note,
              },
            ],
          },
        };
      }

      // otherwise, create a new hive
      const hiveId = generateId.hive();
      const totalHives = prevState.base.hives.length + prevState.added.hives.length;
      return {
        ...updateHistory(prevState),
        selected: [hiveId],
        added: {
          ...prevState.added,
          hives: [
            ...hives,
            {
              name: `Hive ${totalHives + 1}`,
              id: hiveId,
              coordinates: [x, y],
              serial_number: '',
              sensors: [],
              connectionHealth: {
                status: '',
                codes: [],
              },
            },
          ],
        },
      };
    },
    modifyHive: (prevState, action: { payload: Partial<Hive> & { id: string } }) => {
      datadogRum.addAction('Modify hive', { source: 'studio-on-web' });

      const { payload } = action;
      const id = payload.id;
      const {
        modified: { hives },
      } = prevState;
      return {
        ...updateHistory(prevState),

        modified: {
          ...prevState.modified,
          hives: {
            ...hives,
            [id]: { ...hives[id], ...payload },
          },
        },
      };
    },
    delete: (prevState, action: { payload: { ids: string[] } }) => {
      const { ids: deleteIds } = action.payload;
      datadogRum.addAction('Delete items', {
        source: 'studio-on-web',
        count: deleteIds.length,
      });

      const { added, deleted, modified } = prevState;
      const selected = new Set<string>(prevState.selected);
      deleteIds.forEach((id) => selected.delete(id));
      return {
        ...updateHistory(prevState),
        selected: Array.from(selected),
        modified: {
          // TODO: we should enforce that "id" is present on all resources in our typings
          floorplans: pickBy(modified.floorplans, (r) => !r.id || !deleteIds.includes(r.id)),
          sensors: pickBy(modified.sensors, (r) => !r.id || !deleteIds.includes(r.id)),
          rooms: pickBy(modified.rooms, (r) => !r.id || !deleteIds.includes(r.id)),
          zones: pickBy(modified.zones, (r) => !r.id || !deleteIds.includes(r.id)),
          hives: pickBy(modified.hives, (r) => !r.id || !deleteIds.includes(r.id)),
        },
        added: {
          sensors: added.sensors.filter((r) => !deleteIds.includes(r.id)),
          rooms: added.rooms.filter((r) => !deleteIds.includes(r.id)),
          zones: added.zones.filter((r) => !deleteIds.includes(r.id)),
          hives: added.hives.filter((r) => !deleteIds.includes(r.id)),
          floorplans: added.floorplans.filter((r) => !deleteIds.includes(r.id)),
        },
        deleted: [...deleted, ...deleteIds],
      };
    },
    undo: (prevState) => {
      const { undoState } = prevState;
      if (undoState.length === 0) {
        return prevState;
      }
      const lastState = undoState[undoState.length - 1];
      return {
        ...lastState,
        // preserve current base state (API data)
        base: prevState.base,
        undoState: undoState.slice(0, undoState.length - 1),
        redoState: [...prevState.redoState, prevState].slice(-historyCapacity),
      };
    },
    redo: (prevState) => {
      const { redoState } = prevState;
      if (redoState.length === 0) {
        return prevState;
      }
      const lastState = redoState[redoState.length - 1];
      return {
        ...lastState,
        // preserve current base state (API data)
        base: prevState.base,
        redoState: redoState.slice(0, redoState.length - 1),
        undoState: [...prevState.undoState, prevState].slice(-historyCapacity),
      };
    },
    updateError: (prevState, action: { payload: { macAddress: string[]; message: string } }) => {
      const { macAddress: errorIds, message } = action.payload;
      const newErrors = { ...prevState.errors };
      errorIds.forEach((id) => {
        newErrors[id] = message;
      });
      return {
        ...prevState,
        errors: newErrors,
      };
    },
    removeError: (prevState, action: { payload: { macAddress: string[] } }) => {
      const { macAddress: errorIds } = action.payload;
      const newErrors = { ...prevState.errors };
      errorIds.forEach((id) => {
        delete newErrors[id];
      });
      return {
        ...prevState,
        errors: newErrors,
      };
    },
    set: (prevState, action: { payload: CoreReducerState['base'] }) => {
      const { sensors, rooms, zones, floorplans, hives, unplotted } = action.payload;
      return {
        ...prevState,
        base: {
          sensors,
          rooms,
          zones,
          hives,
          unplotted,
          floorplans,
        },
        initialized: true,
      };
    },
    clearEditState: (prevState) => {
      const { modified, deleted, added } = getEmptyState();
      return { ...prevState, modified, deleted, added };
    },
    update: (
      prevState,
      action: {
        payload: SaveResult;
      },
    ) => {
      const { base, modified, added } = prevState;
      const {
        updateSensors,
        updateRooms,
        updateZones,
        updateHives,
        updateFloorplans,
        deleteSensors,
        deleteFloorplans,
        deleteHives,
        deleteRooms,
        deleteZones,
        createRooms,
        createZones,
      } = action.payload;

      let addedSensors = [...added.sensors];
      let addedRooms = [...added.rooms];
      let addedZones = [...added.zones];
      let addedHives = [...added.hives];
      const modifiedSensors = { ...modified.sensors };
      const modifiedRooms = { ...modified.rooms };
      const modifiedZones = { ...modified.zones };
      const modifiedHives = { ...modified.hives };
      deleteSensors.forEach(({ id }) => {
        delete modifiedSensors[id];
        addedSensors = addedSensors.filter(({ id: _id }) => id !== _id);
      });
      updateSensors.forEach(({ macAddress }) => {
        const foundId = Object.values(modifiedSensors).find(
          (sensor) => sensor.macAddress === macAddress,
        )?.id;
        if (foundId) {
          delete modifiedSensors[foundId];
        }
        addedSensors = addedSensors.filter(({ id: _id }) => foundId !== _id);
      });
      [...updateRooms, ...createRooms, ...deleteRooms].forEach(({ id }) => {
        delete modifiedRooms[id];
        addedRooms = addedRooms.filter(({ id: _id }) => id !== _id);
      });
      [...updateZones, ...createZones, ...deleteZones].forEach(({ id }) => {
        delete modifiedZones[id];
        addedZones = addedZones.filter(({ id: _id }) => id !== _id);
      });
      [...updateHives, ...deleteHives].forEach(({ id }) => {
        delete modifiedHives[id];
        addedHives = addedHives.filter(({ id: _id }) => id !== _id);
      });
      // Hives don't use idempotent creation, so we need to handle it differently.
      // Luckily we can use the serial number as a stable id
      updateHives.forEach(({ serial_number }) => {
        // The hive may have just been created, so we may be missing the id.
        // In that case we need to use the serial number as an id.
        const foundId = Object.values(modifiedHives).find((h) => h.serial_number === serial_number)
          ?.id;
        if (foundId) {
          delete modifiedHives[foundId];
          addedHives = addedHives.filter(({ id: _id }) => foundId !== _id);
        }
      });

      // Floor plans don't support idempotent creation and also don't really have a stable id.
      // For now, we just clear out all floor plan changes.

      return {
        ...prevState,
        modified: {
          sensors: modifiedSensors,
          rooms: modifiedRooms,
          zones: modifiedZones,
          hives: modifiedHives,
          floorplans: {},
        },
        deleted: [],
        added: {
          floorplans: [],
          hives: addedHives,
          rooms: addedRooms,
          sensors: addedSensors,
          zones: addedZones,
        },
        base: {
          sensors: [
            ...base.sensors
              .filter((s) => !deleteSensors.some((u) => u.id === s.id))
              .filter((s) => !updateSensors.some((u) => u.id === s.id)),
            ...updateSensors,
          ],
          rooms: [
            ...base.rooms
              .filter((s) => !deleteRooms.some((u) => u.id === s.id))
              .filter((s) => !updateRooms.some((u) => u.id === s.id)),
            ...createRooms.map((r) => ({ ...r, sensors: [] })),
            ...updateRooms,
          ],
          zones: [
            ...base.zones
              .filter((s) => !deleteZones.some((u) => u.id === s.id))
              .filter((s) => !updateZones.some((u) => u.id === s.id)),
            ...createZones,
            ...updateZones,
          ],
          hives: [
            ...base.hives
              .filter((s) => !deleteHives.some((u) => u.id === s.id))
              .filter((s) => !updateHives.some((u) => u.id === s.id)),
            ...updateHives,
          ],
          unplotted: [
            ...base.unplotted
              .filter((s) => !deleteHives.some((u) => u.id === s.id))
              .filter((s) => !updateHives.some((u) => u.id === s.id)),
          ],
          floorplans: [
            ...base.floorplans
              .filter((s) => !deleteFloorplans.some((u) => u.id === s.id))
              .filter((s) => !updateFloorplans.some((u) => u.id === s.id)),
            ...updateFloorplans.map(({ coordinates, ...rest }) => {
              return { ...rest, coordinates, rotation: getRectRotation(coordinates) };
            }),
          ],
        },
        initialized: true,
      };
    },
    reset: (prev) => {
      // special case: preserve some settings
      const highlightRelatedDevices = prev.highlightRelatedDevices;
      const newState = getEmptyState();
      newState.highlightRelatedDevices = highlightRelatedDevices;
      return newState;
    },
    setLayerState: (
      prevState,
      action: { payload: { layer: LayerName; state: Partial<LayerState> } },
    ) => {
      const { layer, state } = action.payload;

      const newSelected = new Set(prevState.selected);
      if (layer === 'floorplans' && state.locked) {
        // when floorplans are locked, we must deselect all floorplans
        [...prevState.base.floorplans, ...prevState.added.floorplans]
          .map((floorplan) => floorplan.id)
          .forEach(newSelected.delete.bind(newSelected));
      }

      return {
        ...prevState,
        selected: Array.from(newSelected),
        layers: {
          ...prevState.layers,
          [layer]: { ...prevState.layers[layer], ...state },
        },
      };
    },
    toggleSubLayer: (
      prevState,
      action: { payload: { layer: LayerName; subLayer: string; toggle: boolean } },
    ) => {
      const { layer, subLayer, toggle } = action.payload;
      let subLayers = prevState.layers[layer].subLayers ?? [];
      if (toggle) {
        subLayers = [...subLayers, subLayer].sort();
      } else {
        subLayers = subLayers.filter((m) => m !== subLayer);
      }
      return {
        ...prevState,
        layers: {
          ...prevState.layers,
          [layer]: { ...prevState.layers[layer], subLayers },
        },
      };
    },

    setSearchText: (prevState, action: { payload: string }) => {
      return {
        ...prevState,
        search: { ...prevState.search, text: action.payload },
      };
    },

    setSearchHoverId: (prevState, action: { payload: string | null }) => {
      return {
        ...prevState,
        search: { ...prevState.search, hoverItemId: action.payload },
      };
    },

    toggleHighlightRelatedDevices: (prevState) => {
      return {
        ...prevState,
        highlightRelatedDevices: !prevState.highlightRelatedDevices,
      };
    },

    setSelectedHovered: (
      prevState,
      action: {
        payload: {
          hover: boolean;
        };
      },
    ) => {
      return {
        ...prevState,
        selectedHovered: action.payload.hover,
      };
    },
  },
});

export default studioSlice.reducer;
export const { actions: studioActions } = studioSlice;

export function useStudioState() {
  return useAppSelector((state) => state.studio);
}
