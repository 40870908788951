import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import SettingsHeader from './SettingsHeader';
import { LoadingSpinner } from '@/components';
import { useFeatureGate } from '@statsig/react-bindings';

const TagManagement = lazy(() => import('./TagManagement'));
const TokenManagement = lazy(() => import('./TokenManagement'));

export const SettingsPage = () => {
  const { value: enableApiTokens } = useFeatureGate('api_tokens');
  return (
    <div className="bg-gray-50 w-full">
      {/* we can make the input text dynamic when there are other funcitons to display on the settings page, beyond just tags */}
      <Suspense fallback={<LoadingSpinner />}>
        <Switch>
          {enableApiTokens && (
            <Route exact path="/settings/tokens">
              <SettingsHeader headerText="Token Management" subText="Manage your API tokens" />
              <TokenManagement />
            </Route>
          )}
          <Route exact path="/settings/tags">
            <SettingsHeader
              headerText="Tags Management"
              subText="Manage your preferences and settings"
            />
            <TagManagement />
          </Route>
          <Redirect to="/settings/tags" />
        </Switch>
      </Suspense>
    </div>
  );
};

export default SettingsPage;
