import type { TRectCoords, TXYPoint, TInDirection } from '@/types/Common';
import { ESensorMode, ESensorModel } from '@/types/Sensor';
import { EINSTALLATIONMODE } from '@/types/Space';
import { gql, useQuery } from '@apollo/client';
import { keyBy } from 'lodash';
import {
  SensorConnectionHealthStatus,
  SensorHealthCodeInfo,
} from '../../../pages/DeviceManagementPage/SensorTable';
import { HiveConnectionHealthStatus } from '../../../pages/DeviceManagementPage/HiveTable';
import type { Capacity } from './types';
import { ConnectionHealthCodeStatusHive } from '@/.generated/graphql';
import { getRectRotation } from '../utils/utils';

export const GET_SPACE_STATE = gql`
  query StudioGetSpace($ids: [String!]!) {
    floors(ids: $ids) {
      data {
        id: floor_id
        name
        timezone
        installation_status
        floorPlans: floor_plans {
          id: floor_plan_id
          name
          coordinates
          url
        }
        tags {
          name
          id
        }
        sensors {
          id: sensor_id
          name
          center
          mode
          model
          orientation
          isOnline: is_online
          inDirection: in_direction
          clientId: client_id
          connectionHealth: connection_health {
            status
            codes {
              lastTimestamp: last_timestamp
              comparedTimestamp: compared_timestamp
              code
              isError: is_error
              description
            }
          }
          macAddress: mac_address
          fov: field_of_view
          height
          isEntrance: is_entrance
          doorLine: door_line
          parallelToDoor: parallel_to_door
          hive {
            id: hive_id
            name
            serialNumber: serial_number
            config {
              apiDrivenSensorMode: api_driven_sensor_mode
            }
          }
          algoConfig: algo_config {
            coldPersonDetection: cold_person_detection
            sunlight
            shortenedDoorline: shortened_doorline
          }
          room {
            name
            id: room_id
            coordinates
            rotation
          }
          lastBatteryChangeDate: last_battery_change_date
          nextBatteryChangeDate: next_battery_change_date
          note
        }
        rooms {
          id: room_id
          name
          coordinates
          rotation
          capacity {
            max
            mid
          }
          sensors {
            id: sensor_id
            name
          }
          note
          tags {
            name
            id
          }
        }
        zones {
          id: zone_id
          name
          coordinates
          rotation
          capacity {
            max
            mid
          }
          note
          tags {
            name
            id
          }
        }
        hives {
          id: hive_id
          name
          coordinates
          serial_number
          networkId: last_network_id
          sensors {
            id: sensor_id
            macAddress: mac_address
          }
          connectionHealth: connection_health {
            status
            codes {
              lastTimestamp: last_timestamp
              comparedTimestamp: compared_timestamp
              code
              isError: is_error
              description
            }
          }
          note
        }
      }
    }
  }
`;

export type Sensor = {
  id: string;
  name: string;
  center: TXYPoint;
  mode: ESensorMode;
  macAddress: string;
  orientation: [number, number, number];
  fov: number;
  height: number;
  isEntrance?: boolean;
  model: ESensorModel;
  doorLine?: number;
  parallelToDoor?: boolean;
  inDirection?: TInDirection;
  clientId?: string;
  connectionHealth?: {
    status: SensorConnectionHealthStatus;
    codes: SensorHealthCodeInfo[];
  };
  room?: {
    name: string;
    id: string;
    coordinates: TRectCoords;
    rotation: number;
  };
  hive?: {
    id: string;
    name: string;
    serialNumber: string;
    config?: {
      apiDrivenSensorMode: boolean;
    } | null;
  };
  algoConfig?: {
    shortenedDoorline?: [number, number];
    coldPersonDetection?: boolean;
    sunlight?: boolean;
  };
  // Custom scalar is not yet supported in apollo client
  // https://community.apollographql.com/t/custom-scalars-on-the-client-side/6587/3
  lastBatteryChangeDate?: string | null;
  nextBatteryChangeDate?: string | null;
  note?: string;
  /**
   * Mouse event can be blocked by selection box.
   * We need manually set hover state to the item when necessary
   */
  selectedHover?: boolean;
};

export type Room = {
  id: string;
  name: string;
  coordinates: TRectCoords;
  rotation: number;
  capacity: Capacity;
  sensors: {
    id: string;
    name: string;
  }[];
  note?: string;
  /**
   * Mouse event can be blocked by selection box.
   * We need manually set hover state to the item when necessary
   */
  selectedHover?: boolean;
};

export type Zone = {
  id: string;
  name: string;
  room_id?: string;
  room?: Room;
  coordinates: TRectCoords;
  rotation: number;
  capacity: Capacity;
  note?: string;
  /**
   * Mouse event can be blocked by selection box.
   * We need manually set hover state to the item when necessary
   */
  selectedHover?: boolean;
};

export type Hive = {
  id: string;
  name: string;
  coordinates: TXYPoint;
  serial_number: string;
  sensors: {
    id: string;
    macAddress: string;
  }[];
  networkId?: number;
  connectionHealth: {
    status: HiveConnectionHealthStatus;
    // can't use the generated gql types as they have snake_case fields...
    codes: {
      lastTimestamp: number;
      comparedTimestamp: number;
      code: ConnectionHealthCodeStatusHive;
      description: string;
      isError: boolean;
    }[];
  };
  note?: string;
};

export type FloorPlan = {
  id: string;
  url: string;
  name: string;
  coordinates: TRectCoords;
  base64?: string;
  aspectRatioLocked: boolean;
  rotation: number;
};

export type Floor = {
  id: string;
  name: string;
  timezone: string;
  sensors: Sensor[];
  rooms: Room[];
  zones: Zone[];
  hives: Hive[];
  floorPlans: FloorPlan[];
  installation_status: EINSTALLATIONMODE;
};

type StateData = {
  floors: {
    data: Floor[];
  };
};

export type Item = Sensor | Room | Zone | Hive | FloorPlan;

export const useGetState = ({ spaceId, skip }: { spaceId?: string; skip?: boolean }) => {
  const { data, ...rest } = useQuery<StateData>(GET_SPACE_STATE, {
    variables: { ids: [spaceId] },
    pollInterval: 1000 * 5,
    skip: skip || !spaceId,
  });

  const floorData = data?.floors?.data[0];

  // populate containing room for each zone if any
  const allRooms = keyBy(floorData?.rooms ?? ([] as Room[]), 'id');
  const allZones = floorData?.zones ?? ([] as Zone[]);
  allZones.forEach((zone) => {
    if (zone.room_id && zone.room_id in allRooms) {
      zone.room = allRooms[zone.room_id];
    }
  });

  return {
    data: floorData
      ? {
          ...floorData,
          floorPlans:
            floorData?.floorPlans?.map((fp) => ({
              ...fp,
              // Floor plans don't have true rotation yet, it's all based on the order of the coordinates....
              rotation: getRectRotation(fp.coordinates),
            })) ?? [],
        }
      : null,
    ...rest,
  };
};
