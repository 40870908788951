import { useContext } from 'react';
import {
  StatsigProvider as StatsigProviderCore,
  useClientAsyncInit,
  type StatsigUser,
} from '@statsig/react-bindings';
import { useAuthUser } from './hooks';
import { UserContext } from './contexts';
import { LoadingSpinner } from './components';

const StatsigProvider = ({ user, children }: { user: StatsigUser; children: React.ReactNode }) => {
  const { client, isLoading } = useClientAsyncInit(import.meta.env.VITE_STATSIG_SDK_KEY, user, {
    networkConfig: {
      preventAllNetworkTraffic: import.meta.env.MODE === 'test',
    },
    environment: {
      tier:
        import.meta.env.MODE === 'production'
          ? 'production'
          : import.meta.env.MODE === 'staging'
          ? 'staging'
          : 'development',
    },
  });

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return <StatsigProviderCore client={client}>{children}</StatsigProviderCore>;
};

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  const { name, user: authUser, clientId } = useAuthUser();
  const { roles, isSuperUser } = useContext(UserContext);
  const user: StatsigUser | null =
    authUser && name && clientId
      ? {
          email: authUser.email,
          appVersion: import.meta.env.VITE_APP_GIT_SHA,
          userID: name,
          custom: {
            roles,
            superUser: isSuperUser,
            clientId,
          },
          customIDs: {
            clientId,
          },
        }
      : null;

  if (!user) {
    return <>{children}</>;
  }

  return <StatsigProvider user={user}>{children}</StatsigProvider>;
};

export default Wrapper;
