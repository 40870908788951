import React from 'react';
import { Tabs } from 'antd';
import { useHistory } from 'react-router-dom';
import { useFeatureGate } from '@statsig/react-bindings';

const { TabPane } = Tabs;

const SettingsTabs: React.FC = () => {
  const history = useHistory();

  const handleTabClick = (key: string) => {
    history.push(`/settings/${key}`);
  };

  const activeKey = history.location.pathname.split('/').pop();

  const { value: apiTokens } = useFeatureGate('api_tokens');

  return (
    <div className="flex justify-end w-full">
      <Tabs tabPosition="left" onTabClick={handleTabClick} activeKey={activeKey}>
        <TabPane tab="Tags" key="tags" />
        {apiTokens && <TabPane tab="API tokens" key="tokens" />}
      </Tabs>
    </div>
  );
};

export default SettingsTabs;
