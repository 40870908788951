import { MouseEvent as ReactMouseEvent } from 'react';
import * as pdfjs from 'pdfjs-dist';
import {
  ESensorMode,
  type TBounds,
  type TRectCoords,
  type TRectangle,
  type TXYPoint,
  type TSensorDirection,
} from '@/types';
import {
  type FloorPlan,
  type Room,
  type Sensor,
  type Zone,
  Hive,
  Item,
} from '../ScopingEditor/useGetState';
import { HIVE_ICON_SIZE } from '../items/HiveItem';
import pointInPolygon from 'point-in-polygon';
import Flatten, { Polygon, Ray, point, vector } from '@flatten-js/core';
import { RoomObject, SensorObject } from '../sdk';

// TODO: break this file down into smaller modules

export const PERSON_SIZE = 0.36;
export const MOUNT_ANGLE = 15;

// Accepts the origin and target points, and the zoom level.
// Returns the new origin point.
export function zoomToPoint(
  origin: TXYPoint,
  target: TXYPoint,
  prevZoom: number,
  nextZoom: number,
): TXYPoint {
  const [x, y] = origin;
  const [tx, ty] = target;
  const zoomRatio = prevZoom / nextZoom;
  return [tx + (x - tx) * zoomRatio, ty + (y - ty) * zoomRatio];
}

/** Converts an array of points into a rectangle that contains all of them. */
export function pointsToRect(...points: TXYPoint[]): TRectangle {
  const xpts = points.map(([x]) => x);
  const ypts = points.map(([, y]) => y);
  const x = Math.min(...xpts);
  const y = Math.min(...ypts);
  // Decimal operation causes inaccuracy in javascript, it's recommended to multiply the value to integer before doing subtraction or addition
  const width = (Math.max(...xpts) * 100 - x * 100) / 100;
  const height = (Math.max(...ypts) * 100 - y * 100) / 100;
  return [x, y, width, height];
}

/** Converts a rectangle (x,y,width,height) into a set of coordinates for each corner. */
export function rectToPoints(x: number, y: number, width: number, height: number): TRectCoords {
  return [
    [x, y],
    [x + width, y],
    [x + width, y + height],
    [x, y + height],
  ];
}

export type ItemType = 'room' | 'sensor' | 'floorplan' | 'hive' | 'zone';

/** Converts an item id into a type as a string */
export function getTypeFromId(id: string): ItemType | null {
  if (id.toLowerCase().startsWith('room')) return 'room';
  if (id.toLowerCase().startsWith('sensor')) return 'sensor';
  if (id.toLowerCase().startsWith('floorplan')) return 'floorplan';
  if (id.toLowerCase().startsWith('hive')) return 'hive';
  if (id.toLowerCase().startsWith('zone')) return 'zone';

  console.warn('Unknown item type', id);
  return null;
}

/** Converts any type of item into rectangular coordinates. */
export function itemToCoordinates(
  item: Room | Sensor | Zone | FloorPlan | Hive,
  adjustedZoom?: number,
): TXYPoint[] {
  if (getTypeFromId(item.id) === 'sensor') {
    return SensorObject.fromSensor(item as Sensor).coverageCoordinates;
  }
  if (getTypeFromId(item.id) === 'hive') {
    const hiveItem = item as Hive;
    adjustedZoom = adjustedZoom || 1;
    const [x = 0, y = 0] = hiveItem.coordinates;
    return [
      [x - HIVE_ICON_SIZE / 2 / adjustedZoom, y - HIVE_ICON_SIZE / 2 / adjustedZoom],
      [x + HIVE_ICON_SIZE / 2 / adjustedZoom, y - HIVE_ICON_SIZE / 2 / adjustedZoom],
      [x + HIVE_ICON_SIZE / 2 / adjustedZoom, y + HIVE_ICON_SIZE / 2 / adjustedZoom],
      [x - HIVE_ICON_SIZE / 2 / adjustedZoom, y + HIVE_ICON_SIZE / 2 / adjustedZoom],
    ];
  }
  if (getTypeFromId(item.id) === 'floorplan') {
    const floorPlanItem = item as FloorPlan;
    return floorPlanItem.coordinates;
  }

  const roomOrZoneItem = item as Room | Zone;
  return roomOrZoneItem.coordinates;
}

/** Gets the size of a sensor's coverage from a height and FOV number. */
export const calculateSensorCoverage = ({
  height,
  fov,
}: {
  height: number;
  fov: number;
}): number => {
  if (fov === 90) {
    return 0.84 * (2 * Math.tan(DEG_TO_RAD * (fov / 2)) * (height - 1.05) + PERSON_SIZE * 2);
  }
  return 2 * Math.tan(DEG_TO_RAD * (fov / 2)) * (height - 1.05) + PERSON_SIZE * 2;
};

export const sumRectangles = (rect1: TRectangle, rect2: TRectangle): TRectangle =>
  rect1.map((val, i) => val + rect2[i]) as TRectangle;

export const sumPoints = (...points: TXYPoint[]): TXYPoint =>
  points.reduce((acc, [x, y]) => [acc[0] + x, acc[1] + y], [0, 0]);

export const scalePoint = (p: TXYPoint, scale: number): TXYPoint =>
  p.map((val) => val * scale) as TXYPoint;

/** Rotates a point counter-clockwise around an origin point. Angle is in degrees. */
export function rotatePointAroundOrigin(p: TXYPoint, origin: TXYPoint, angle: number): TXYPoint {
  const [px, py] = p;
  const [ox, oy] = origin;
  const radians = (angle * Math.PI) / 180;
  const x = ox + (px - ox) * Math.cos(radians) - (py - oy) * Math.sin(radians);
  const y = oy + (px - ox) * Math.sin(radians) + (py - oy) * Math.cos(radians);
  return [x, y];
}

export const roundFloat = (num: number, decimals = 2): number => {
  const res = Math.round(num * 10 ** decimals) / 10 ** decimals;
  if (Object.is(res, -0)) return 0;
  return res;
};
export const roundPoint = ([x, y]: TXYPoint, decimals = 2): TXYPoint => [
  roundFloat(x, decimals),
  roundFloat(y, decimals),
];

/** Converts a mouse event into SVG coordinates */
export const getSvgCursor = (
  event: ReactMouseEvent | MouseEvent,
  svg: SVGSVGElement | SVGGElement,
) => {
  const p = new DOMPoint(event.clientX, event.clientY);
  return p.matrixTransform(svg.getScreenCTM()?.inverse() ?? DOMMatrix.fromMatrix());
};

/** Converts SVG coordinates into screen coordinates */
export const svgToScreenCoordinates = (p: TXYPoint, svg: SVGSVGElement | SVGGElement): TXYPoint => {
  const [x, y] = p;
  const svgPoint = new DOMPoint(x, y);
  const result = svgPoint.matrixTransform(svg.getScreenCTM() ?? DOMMatrix.fromMatrix());
  return [result.x, result.y];
};

export const DEG_TO_RAD = Math.PI / 180;

export const RAD_TO_DEG = 180 / Math.PI;

/** Projects a point into wall coverage space. [0, 0] is the bottom left, [1, 1] is the top right
 * See math here: https://www.notion.so/butlrtech/The-calculation-for-theoretical-effective-coverage-of-wall-mounting-sensors-514261a66dfd4d2095349a366085a576?pvs=4
 * _______________________
 * \                     /
 *  \                   /
 *   \_________________/
 *            x sensor
 */
export const projectPointIntoWallCoverage = (
  p: TXYPoint,
  fov: number,
  height: number,
): TXYPoint => {
  const [px, py] = p;
  const efov = 2 * Math.atan(calculateSensorCoverage({ height, fov }) / (2 * height)) * RAD_TO_DEG;
  const angle = (MOUNT_ANGLE - efov / 2 + efov * py) * DEG_TO_RAD;
  const y = height * Math.tan(angle);
  const xRange = (2 * height * Math.sin((efov / 2) * DEG_TO_RAD)) / Math.cos(angle);
  const x = lerp(-xRange / 2, xRange / 2, px);
  return [x, y];
};

/**
 * Returns the sensor coverage in points (that form a trapezoid) for a wall-mounted sensor.
 */
export function calculateWallMountSensorCoverage(
  sensorCenter: TXYPoint,
  fov: number,
  height: number,
): TBounds {
  return {
    bottomLeft: sumPoints(sensorCenter, projectPointIntoWallCoverage([0, 0], fov, height)),
    bottomRight: sumPoints(sensorCenter, projectPointIntoWallCoverage([1, 0], fov, height)),
    topRight: sumPoints(sensorCenter, projectPointIntoWallCoverage([1, 1], fov, height)),
    topLeft: sumPoints(sensorCenter, projectPointIntoWallCoverage([0, 1], fov, height)),
  };
}

/** Interpolates between a and b (0% is a, 100% is b) */
export function lerp(a: number, b: number, t: number): number {
  return a + (b - a) * t;
}

/** Interpolates a point between a and b (0% is a, 100% is b) */
export function lerpPoint(a: TXYPoint, b: TXYPoint, t: number): TXYPoint {
  return [lerp(a[0], b[0], t), lerp(a[1], b[1], t)];
}

export const isItemSnappable = (item: Room | Zone | FloorPlan | Hive | Sensor): boolean => {
  const itemType = getTypeFromId(item.id);
  return (
    (itemType === 'sensor' && (item as Sensor).mode !== ESensorMode.REPEATER) ||
    itemType === 'room' ||
    itemType === 'zone'
  );
};

/** Calculates the inclination degree between a line and x-axis, the value is from 0 to 360 degrees.  */
export const calculateInclination = (x1: number, y1: number, x2: number, y2: number) => {
  const deltaX = x2 - x1;
  const deltaY = y2 - y1;
  return clampAngleDegrees(Math.atan2(deltaY, deltaX) * RAD_TO_DEG + 180);
};

export function toFlattenPoint(p: TXYPoint): Flatten.Point {
  return point(p[0], p[1]);
}

/**
 * Given a point and bounds, returns distances and intersection points in four directions to
 * the bounds. Optionally takes a rotation of the four directions in degrees.
 * @returns An object with four keys, each containing a distance and an intersection point.
 * */
export function pointToBounds(
  center: TXYPoint,
  bounds: TXYPoint[],
  rotation: number = 0,
): Record<
  'top' | 'right' | 'bottom' | 'left',
  { distance: number; start: TXYPoint; end: TXYPoint } | undefined
> {
  const [x, y] = center;
  const rotationRad = rotation * DEG_TO_RAD;
  const centerPoint = point(x, y);
  const boundsPolygon = new Polygon(bounds);
  const intersections = {
    bottom: new Ray(centerPoint, vector(1, 0).rotate(rotationRad)).intersect(boundsPolygon)[0],
    right: new Ray(centerPoint, vector(0, 1).rotate(rotationRad)).intersect(boundsPolygon)[0],
    top: new Ray(centerPoint, vector(-1, 0).rotate(rotationRad)).intersect(boundsPolygon)[0],
    left: new Ray(centerPoint, vector(0, -1).rotate(rotationRad)).intersect(boundsPolygon)[0],
  };
  return Object.entries(intersections).reduce(
    (acc, [key, intersection]) => {
      if (!intersection) return acc;
      return {
        ...acc,
        [key]: {
          distance: intersection.distanceTo(centerPoint)[0],
          start: center,
          end: [intersection.x, intersection.y],
        },
      };
    },
    {} as Record<
      'top' | 'right' | 'bottom' | 'left',
      { distance: number; start: TXYPoint; end: TXYPoint } | undefined
    >,
  );
}

/**
 * Given coordinates and bounds, returns distances between midPoints to boundaries and
 * intersection points in four directions to the bounds. Optionally takes a rotation of the four directions in degrees.
 * @returns An object with four keys, each containing a distance and intersection points.
 * */
export function itemToBounds(
  bounds: TXYPoint[],
  roomRotation: number = 0,
  coordinates: TRectCoords,
): Record<
  'top' | 'right' | 'bottom' | 'left',
  { distance: number; start: TXYPoint; end: TXYPoint } | undefined
> {
  const rotationRad = roomRotation * DEG_TO_RAD;
  const boundsPolygon = new Polygon(bounds);
  const mids = RoomObject.fromRoom({
    coordinates,
    rotation: 0,
  }).getMidPoints();
  const midPoints = {
    top: toFlattenPoint(mids[3]),
    right: toFlattenPoint(mids[2]),
    left: toFlattenPoint(mids[0]),
    bottom: toFlattenPoint(mids[1]),
  } as Record<'top' | 'right' | 'bottom' | 'left', Flatten.Point>;

  const intersections: Record<'top' | 'right' | 'bottom' | 'left', Flatten.Point | undefined> = {
    bottom: new Ray(midPoints.bottom, vector(1, 0).rotate(rotationRad)).intersect(boundsPolygon)[0],
    right: new Ray(midPoints.right, vector(0, 1).rotate(rotationRad)).intersect(boundsPolygon)[0],
    top: new Ray(midPoints.top, vector(-1, 0).rotate(rotationRad)).intersect(boundsPolygon)[0],
    left: new Ray(midPoints.left, vector(0, -1).rotate(rotationRad)).intersect(boundsPolygon)[0],
  };
  return Object.entries(intersections).reduce(
    (acc, [key, intersection]) => {
      if (!intersection) return acc;

      const midPoint: Flatten.Point = midPoints[key as 'top' | 'right' | 'bottom' | 'left'];
      return {
        ...acc,
        [key]: {
          distance: intersection.distanceTo(midPoint)[0],
          end: [intersection.x, intersection.y],
          start: [midPoint.x, midPoint.y],
        },
      };
    },
    {} as Record<
      'top' | 'right' | 'bottom' | 'left',
      { distance: number; end: TXYPoint; start: TXYPoint } | undefined
    >,
  );
}

/**
 * Parse a file to PDF documents.
 * @param file
 * @returns
 */
export async function parsePDF(file: File): Promise<pdfjs.PDFDocumentProxy> {
  // Manually loading the worker so that Vite properly bundles it.
  const worker = new Worker(new URL('pdfjs-dist/build/pdf.worker.mjs', import.meta.url), {
    type: 'module',
  });
  pdfjs.GlobalWorkerOptions.workerPort = worker;

  return await pdfjs.getDocument({
    data: await file.arrayBuffer(),
  }).promise;
}

/**
 *
 * @param pdfPage The PDF page to be converted to Data URL
 * @returns Data URL string
 */
export async function convertPDFPageToDataURL(pdfPage: pdfjs.PDFPageProxy) {
  const viewport = pdfPage.getViewport({ scale: 1 });
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  canvas.height = viewport.height;
  canvas.width = viewport.width;

  if (!ctx) {
    throw new Error('Failed to process PDF page');
  }

  await pdfPage.render({ canvasContext: ctx, viewport: viewport }).promise;

  for (let quality = 0.8; quality > 0.1; quality -= 0.01) {
    const dataUrl = canvas.toDataURL('image/jpeg', quality);
    // size should be less than 10MB according to api gateway limitation
    if (dataUrl.length >= 10 * 1024 * 1024) {
      continue;
    }
    return dataUrl;
  }
  alert('Failed to process PDF file is too large to process');
  throw new Error('Failed to process PDF file is too large to process');
}

/**
 * Convert a file to base64 based dataURL
 * @param file
 * @returns base64 based dataURL
 */
export async function toBase64(file: File, pageNum?: number): Promise<string> {
  if (file.type === 'application/pdf') {
    const pdf = await parsePDF(file);
    return convertPDFPageToDataURL(await pdf.getPage(pageNum ? pageNum + 1 : 1));
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result as string;
      resolve(base64String);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
}

/**
 * Takes a set of values and dirty fields from react-hook-form and returns an
 * object representing only the changed values.
 *
 * Source: https://github.com/orgs/react-hook-form/discussions/1991#discussioncomment-6318535
 */
export function getDirtyValues<
  DirtyFields extends Record<string, unknown>,
  Values extends Record<keyof DirtyFields, unknown>,
>(dirtyFields: DirtyFields, values: Values): Partial<typeof values> {
  if (Array.isArray(dirtyFields)) {
    return values;
  }
  const dirtyValues = Object.keys(dirtyFields).reduce((prev, key) => {
    // Unsure when RFH sets this to `false`, but omit the field if so.
    if (!dirtyFields[key]) return prev;

    return {
      ...prev,
      [key]:
        typeof dirtyFields[key] === 'object'
          ? getDirtyValues(dirtyFields[key] as DirtyFields, values[key] as Values)
          : values[key],
    };
  }, {});
  return dirtyValues;
}

export function clamp(value: number, min: number, max: number) {
  return Math.min(Math.max(value, min), max);
}

/** Clamps an angle to between 0 (inclusive) and 360 (exclusive) */
export function clampAngleDegrees(value: number): number {
  return ((value % 360) + 360) % 360;
}

export function clampRotation(value: number, max: number, offset: number) {
  return ((clampAngleDegrees((value * 360) / max + offset) - offset) * max) / 360;
}

/**
 * Assumes that points are in counter-clockwise order,
 * starting from the bottom-left point for 0° rotation,
 * bottom-right for 90° rotation, etc.
 */
export function getRectRotation(points: TRectCoords) {
  // Left bottom corner
  const [x1, y1] = points[0];
  // Right bottom corner
  const [x2, y2] = points[1];
  const rotation =
    x1 === x2
      ? y1 < y2
        ? 90
        : 270
      : y1 === y2
      ? x1 < x2
        ? 0
        : 180
      : (x1 > x2 ? 180 : 0) + Math.atan((y1 - y2) / (x1 - x2)) * RAD_TO_DEG;
  return clampAngleDegrees(parseInt(rotation.toFixed(0)));
}

/**
 * Converts a set of transformed coordinates and a rotation into the backend format.
 * Floor plan rotation follows these rules:
 * - Points are listed in a counter-clockwise order.
 * - Rotation is determined by the first point.
 *   Bottom-left is 0°, going counter-clockwise for each 90° clockwise rotation.
 *   Yes it doesn't make sense that rotation is clockwise but the points are counter-clockwise.
 */
export function convertFloorPlanCoordinates(
  coordinates: TRectCoords,
  rotation: number,
): TRectCoords {
  const [x, y, width, height] = pointsToRect(...coordinates);
  const rectCoordinates: TRectCoords = [
    [x, y],
    [x + width, y],
    [x + width, y + height],
    [x, y + height],
  ];
  const orderedCoordinates: TXYPoint[] = [];
  for (let i = 0; i < 4; i++) {
    orderedCoordinates.push(rectCoordinates[(i + rotation / 90) % 4]);
  }
  return orderedCoordinates as TRectCoords;
}

export function doesItemContainPoint(item: Item, p: TXYPoint) {
  const coordinates = itemToCoordinates(item);
  return pointInPolygon(p, coordinates);
}

/** Converts a list of points into the format that SVG expects */
export function pointsToString(points: TXYPoint[]) {
  return points.map(([px, py]) => `${px},${py}`).join(' ');
}

export function getOriginFromCoordinates(coordinates: TXYPoint[]) {
  return coordinates.reduce(
    (acc, [x, y]) => [acc[0] + x / coordinates.length, acc[1] + y / coordinates.length],
    [0, 0],
  );
}

export function rotateCoordinates(
  coordinates: TXYPoint[],
  rotation: number,
  origin?: TXYPoint,
): TXYPoint[] {
  const rotationOrigin = origin ?? getOriginFromCoordinates(coordinates);
  return coordinates.map((p) =>
    rotatePointAroundOrigin(p, rotationOrigin, rotation),
  ) as TRectCoords;
}

type RotationDirection = 'right' | 'left' | 'up' | 'down';

/**
 * Translates a rotation to a raw string up/down/left/right.
 * If it's not orthogonal (90° increments), then it rounds.
 */
export function getRotationDirection(rotation: number): RotationDirection {
  const clampedRotation = clampAngleDegrees(rotation);
  if (clampedRotation >= 45 && clampedRotation < 135) {
    return 'right';
  }
  if (clampedRotation >= 135 && clampedRotation < 225) {
    return 'down';
  }
  if (clampedRotation >= 225 && clampedRotation < 315) {
    return 'left';
  }
  return 'up';
}

export function getEntryDirection(
  rotation: number,
  inDirection: boolean,
  parallelToDoor: boolean,
): TSensorDirection {
  const rotationDirection = getRotationDirection(rotation);
  if (rotationDirection === 'up') {
    return parallelToDoor
      ? inDirection
        ? 'upward'
        : 'downward'
      : inDirection
      ? 'leftward'
      : 'rightward';
  } else if (rotationDirection === 'right') {
    return parallelToDoor
      ? inDirection
        ? 'rightward'
        : 'leftward'
      : inDirection
      ? 'upward'
      : 'downward';
  } else if (rotationDirection === 'down') {
    return parallelToDoor
      ? inDirection
        ? 'downward'
        : 'upward'
      : inDirection
      ? 'rightward'
      : 'leftward';
  } else {
    return parallelToDoor
      ? inDirection
        ? 'leftward'
        : 'rightward'
      : inDirection
      ? 'downward'
      : 'upward';
  }
}

export function getDoorlineOrientation(
  parallelToDoor: boolean,
  rotation: number,
): 'Horizontal' | 'Vertical' {
  const rotationDirection = getRotationDirection(rotation);
  if (rotationDirection === 'up' || rotationDirection === 'down') {
    return parallelToDoor ? 'Horizontal' : 'Vertical';
  } else {
    return parallelToDoor ? 'Vertical' : 'Horizontal';
  }
}

export function getParallelToDoor(orientation: 'Horizontal' | 'Vertical', rotation: number) {
  const rotationDirection = getRotationDirection(rotation);
  if (rotationDirection === 'up' || rotationDirection === 'down') {
    return orientation === 'Horizontal';
  } else {
    return orientation === 'Vertical';
  }
}

/**
 * Calculate the area of the intersection of two given rectangles.
 * @param param0 // top left corner of first rectangle
 * @param param1 // bottom right corner of first rectangle
 * @param param2 // top left corner of second rectangle
 * @param param3 // bottom right corner of second rectangle
 * @returns
 */
function calculateRectangleOverlap(
  [x1, y1]: TXYPoint, // top left corner of first rectangle
  [x2, y2]: TXYPoint, // bottom right corner of first rectangle
  [x3, y3]: TXYPoint, // top left corner of second rectangle
  [x4, y4]: TXYPoint, // bottom right corner of second rectangle
) {
  const rect: TRectCoords = [
    [x1, y1],
    [x2, y1],
    [x2, y2],
    [x1, y2],
  ];
  if (
    !pointInPolygon([x3, y3], rect) &&
    !pointInPolygon([x4, y3], rect) &&
    !pointInPolygon([x3, y4], rect) &&
    !pointInPolygon([x3, y4], rect)
  ) {
    return 0;
  }
  // Length of intersecting part
  const w = Math.min(x2, x4) - Math.max(x1, x3);
  const h = Math.min(y2, y4) - Math.max(y1, y3);
  if (w > 0 && h > 0) {
    return w * h;
  }
  // this shouldn't happen
  return 0;
}

/**
 * Whether the item should be selected by a box selection.
 * @param item
 * @param param1 left top corner of the box
 * @param param2 right bottom corner of the box
 * @param threshold minimal coverage of the item by the box, default to 0.8
 */
export function isItemBoxSelected(
  item: Item,
  [x1, y1]: TXYPoint,
  [x2, y2]: TXYPoint,
  threshold: number = 0.8,
) {
  if (getTypeFromId(item.id) === 'hive') {
    const hiveItem = item as Hive;
    const [x, y] = hiveItem.coordinates;
    return x1 <= x && x <= x2 && y1 <= y && y <= y2;
  } else if (getTypeFromId(item.id) === 'sensor') {
    const sensorItem = item as Sensor;
    const sensorSize = calculateSensorCoverage(sensorItem) / 2;
    const [cx, cy] = sensorItem.center;
    return (
      calculateRectangleOverlap(
        [x1, y1],
        [x2, y2],
        [cx - sensorSize / 2, cy - sensorSize / 2],
        [cx + sensorSize / 2, cy + sensorSize / 2],
      ) /
        (sensorSize * sensorSize) >
      threshold
    );
  } else if (getTypeFromId(item.id) === 'floorplan') {
    // Floorplan is not allowed in box selection.
    return false;
  } else {
    const roomZoneItem = item as Room | Zone;
    const minX = Math.min(...roomZoneItem.coordinates.map((coor) => coor[0]));
    const maxX = Math.max(...roomZoneItem.coordinates.map((coor) => coor[0]));
    const minY = Math.min(...roomZoneItem.coordinates.map((coor) => coor[1]));
    const maxY = Math.max(...roomZoneItem.coordinates.map((coor) => coor[1]));
    return (
      calculateRectangleOverlap([x1, y1], [x2, y2], [minX, minY], [maxX, maxY]) /
        ((maxY - minY) * (maxX - minX)) >=
      threshold
    );
  }
}

export function getIconCoords(
  mount: string,
  wallMountCoverage: TBounds | null,
  ceilingMountCorner: TXYPoint | null,
  powerIconSize: number,
  rotation: number,
) {
  const topRightCorner = mount === 'wall' ? wallMountCoverage?.topRight : ceilingMountCorner;
  const adjustedSize = Math.max(powerIconSize, 0.8);

  if (!topRightCorner) return [0, 0];

  if (mount === 'ceiling') {
    return [topRightCorner[0] - adjustedSize, topRightCorner[1] - adjustedSize];
  } else if (mount === 'wall' && topRightCorner) {
    if ((rotation > 315 && rotation <= 360) || (rotation >= 0 && rotation <= 45)) {
      return [topRightCorner[0] - 0.9, topRightCorner[1] - adjustedSize];
    } else if (rotation > 45 && rotation <= 135) {
      return [topRightCorner[0] - adjustedSize, topRightCorner[1] + 0.1];
    } else if (rotation > 135 && rotation <= 225) {
      return [topRightCorner[0] + 0.2, topRightCorner[1]];
    } else if (rotation > 225 && rotation <= 315) {
      return [topRightCorner[0] + 0.1, topRightCorner[1] - adjustedSize];
    }
  }
}
export function calculateCeilingMountSensorCorner(
  sensorCenter: TXYPoint,
  height: number,
): TXYPoint {
  const halfHeight = height / 2;
  return [sensorCenter[0] + halfHeight, sensorCenter[1] + halfHeight];
}
