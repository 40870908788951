import clsx from 'clsx';
import { HTMLAttributes } from 'react';

const HEADER_HEIGHT = 'h-[56px] flex-shrink-0';
const TOP_NAV_HEIGHT = 'h-[61px] flex-shrink-0';
const NAV_LINKS_HEIGHT = 'h-[56px] flex-shrink-0';

export function PageLayoutRoot({ className, ...rest }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={clsx('flex w-full flex-row', className)}
      style={{ height: 'calc(100% - 56px)' }}
      {...rest}
    />
  );
}
export function PageLayoutSidebar({ className, ...rest }: HTMLAttributes<HTMLDivElement>) {
  return <div className={clsx('flex flex-col border-r border-gray-200', className)} {...rest} />;
}

export function PageLayoutSidebarLogoContainer({
  className,
  ...rest
}: HTMLAttributes<HTMLDivElement>) {
  return <div className={clsx('flex items-center p-6', HEADER_HEIGHT, className)} {...rest} />;
}

export function PageLayoutMain({ className, ...rest }: HTMLAttributes<HTMLDivElement>) {
  return <div className={clsx('flex flex-col flex-grow min-w-0', className)} {...rest} />;
}

export function PageLayoutHeader({ className, ...rest }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={clsx(
        'flex flex-row w-full items-center justify-between pr-8 pl-10',
        TOP_NAV_HEIGHT,
        className,
      )}
      {...rest}
    />
  );
}

export function PageLayoutNavBar({ className, ...rest }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={clsx(
        'flex w-full flex-row items-center justify-between border-b border-gray-200 pl-10 pr-4 z-[8] bg-white',
        className,
        NAV_LINKS_HEIGHT,
      )}
      {...rest}
    />
  );
}

export function PageLayoutNavBarStart({ className, ...rest }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={clsx('flex h-full items-center justify-center gap-8', className)} {...rest} />
  );
}

export function PageLayoutNavBarEnd({ className, ...rest }: HTMLAttributes<HTMLDivElement>) {
  return <div className={clsx('flex flex-row h-full items-center', className)} {...rest} />;
}

export function PageLayoutMainContent({ className, ...rest }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      id="main-content"
      className={clsx(
        'flex flex-grow flex-row overflow-x-hidden min-h-0 overflow-y-auto',
        className,
      )}
      {...rest}
    />
  );
}
