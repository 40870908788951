import { ESensorMode, TXYPoint } from '@/types';
import { point, vector } from '@flatten-js/core';
import { Sensor } from '../ScopingEditor/useGetState';
import {
  calculateSensorCoverage,
  clampAngleDegrees,
  DEG_TO_RAD,
  lerp,
  MOUNT_ANGLE,
  RAD_TO_DEG,
  rectToPoints,
} from '../utils/utils';
import type { PhysicalObject } from './types';

export type SensorMount = 'ceiling' | 'wall';
/**
 * Represents a sensor and provides functionality for its behavior and properties.
 * Sensors have a position and rotation, plus a number of properties that define their coverage.
 */
export class SensorObject implements PhysicalObject {
  private _position: TXYPoint;
  private _rotation: number;
  private _height: number;
  private _fov: number;
  private _mount: SensorMount;
  private _doorLine: number | null;
  private _parallelToDoor: boolean | null;
  private _inDirection: number | null;
  private _mode: ESensorMode;
  private _shortenedDoorLine: [number, number] | null;

  constructor(args: {
    position: TXYPoint;
    rotation: number;
    height: number;
    fov: number;
    mount: SensorMount;
    mode: ESensorMode;
    doorLine: number | null;
    parallelToDoor: boolean | null;
    inDirection: number | null;
    shortenedDoorLine: [number, number] | null;
  }) {
    this._position = args.position;
    this._rotation = args.rotation;
    this._height = args.height;
    this._fov = args.fov;
    this._mount = args.mount;
    this._mode = args.mode;
    this._doorLine = args.doorLine;
    this._parallelToDoor = args.parallelToDoor;
    this._inDirection = args.inDirection;
    this._shortenedDoorLine = args.shortenedDoorLine;
  }

  static fromSensor(
    sensor: Pick<
      Sensor,
      | 'center'
      | 'orientation'
      | 'height'
      | 'fov'
      | 'doorLine'
      | 'parallelToDoor'
      | 'inDirection'
      | 'mode'
      | 'algoConfig'
    >,
  ): SensorObject {
    return new SensorObject({
      position: sensor.center,
      rotation: sensor.orientation?.[1] ?? 0,
      height: sensor.height,
      fov: sensor.fov,
      mount: sensor.orientation?.[0] === -30 ? 'wall' : 'ceiling',
      mode: sensor.mode,
      doorLine: sensor.doorLine ?? null,
      parallelToDoor: sensor.parallelToDoor ?? null,
      inDirection: sensor.inDirection ?? null,
      shortenedDoorLine: sensor.algoConfig?.shortenedDoorline ?? null,
    });
  }

  get center(): TXYPoint {
    return this._position;
  }

  get rotation(): number {
    return this._rotation;
  }

  get facingDirection(): number {
    return clampAngleDegrees(
      180 + this._rotation + (this._parallelToDoor ? 0 : -90) + (this._inDirection === 1 ? 180 : 0),
    );
  }

  get mount(): SensorMount {
    return this._mount;
  }

  get height(): number {
    return this._height;
  }

  get fov(): number {
    return this._fov;
  }

  /** Returns the raw numerical door line value. */
  get doorLine(): number | null {
    return this._doorLine;
  }

  /**
   * Returns start and end coordinates of the door line if this sensor has one.
   * Note that only sensors in traffic mode have door lines.
   */
  get doorLineCoordinates(): [TXYPoint, TXYPoint] | null {
    const { doorLine } = this;
    if (doorLine === null || this._mode !== ESensorMode.TRAFFIC) {
      return null;
    }
    const start = this._shortenedDoorLine?.[0] ?? 0;
    const end = this._shortenedDoorLine?.[1] ?? 1;
    if (this._parallelToDoor) {
      return [
        this.projectPointIntoCoverage([start, doorLine]),
        this.projectPointIntoCoverage([end, doorLine]),
      ];
    }
    return [
      this.projectPointIntoCoverage([1 - doorLine, start]),
      this.projectPointIntoCoverage([1 - doorLine, end]),
    ];
  }

  /** Returns the coordinates of this sensor's coverage. */
  get coverageCoordinates(): TXYPoint[] {
    return rectToPoints(0, 0, 1, 1).map((p) => this.projectPointIntoCoverage(p));
  }

  /**
   * Project a point into wall mount coverage.
   * Documented [here](https://www.notion.so/butlrtech/The-calculation-for-theoretical-effective-coverage-of-wall-mounting-sensors-514261a66dfd4d2095349a366085a576?pvs=4).
   */
  private projectPointIntoWallMountCoverage(p: TXYPoint): TXYPoint {
    const { height, fov, rotation } = this;
    const [px, py] = p;
    const efov =
      2 * Math.atan(calculateSensorCoverage({ height, fov }) / (2 * height)) * RAD_TO_DEG;
    const angle = (MOUNT_ANGLE - efov / 2 + efov * py) * DEG_TO_RAD;
    const y = height * Math.tan(angle);
    const xRange = (2 * height * Math.sin((efov / 2) * DEG_TO_RAD)) / Math.cos(angle);
    const x = lerp(-xRange / 2, xRange / 2, px);
    const result = point([x, y])
      .translate(vector(this.center))
      .rotate(-rotation * DEG_TO_RAD, point(this.center));
    return [result.x, result.y];
  }

  /** Project a point into ceiling coverage by simple linear interpolation. */
  private projectPointIntoCeilingCoverage(p: TXYPoint): TXYPoint {
    const { height, fov, rotation } = this;
    const [px, py] = p;
    const sensorSize = calculateSensorCoverage({ height, fov });
    const result = point([
      lerp(-sensorSize / 2, sensorSize / 2, px),
      lerp(-sensorSize / 2, sensorSize / 2, py),
    ])
      .translate(vector(this.center))
      .rotate(-rotation * DEG_TO_RAD, point(this.center));
    return [result.x, result.y];
  }

  /** Projects a point into the coverage area. (0, 0) is the bottom left, (1, 1) is the top right. */
  projectPointIntoCoverage(p: TXYPoint): TXYPoint {
    if (this.mount === 'ceiling') {
      return this.projectPointIntoCeilingCoverage(p);
    }
    return this.projectPointIntoWallMountCoverage(p);
  }

  rotate(angle: number, center: TXYPoint = this.center): SensorObject {
    const nextPosition = point(this._position).rotate(-(angle * DEG_TO_RAD), point(center));
    const { x, y } = nextPosition;
    this._position = [x, y];
    this._rotation = clampAngleDegrees(this._rotation + angle);
    return this;
  }

  translate(dx: number, dy: number): SensorObject {
    const [x, y] = this._position;
    this._position = [x + dx, y + dy];
    return this;
  }
}
