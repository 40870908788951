import { EDateRangePickerType } from '@/components';
import { IEpochRange, ITime } from '@/types';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import type { RootState } from '.';

export enum TIME_RANGE_MODE {
  CUSTOM = 0,
  TODAY,
  LAST_5_MINS,
  THIS_WEEK,
  THIS_MONTH,
  THIS_YEAR,
  YESTERDAY,
  SEVEN_DAYS,
  THIRTY_DAYS,
}

export const INTERVAL = {
  MINUTE: '60',
  QUARTER_HOUR: '900',
  HALF_HOUR: '1800',
  HOURLY: '3600',
  DAILY: '86400',
  WEEKLY: '604800',
};

export function getCurrentTimeState(tz: string, interval?: string): ITime {
  const currentDate = new Date();
  return {
    currentDate,
    timezone: tz,
    interval: interval ?? INTERVAL.HOURLY,
    chart: {
      timeRange: {
        startEpoch: dayjs(currentDate).tz(tz).startOf('day').valueOf(),
        endEpoch: dayjs(currentDate).tz(tz).valueOf(),
      },
      timeRangeMode: TIME_RANGE_MODE.TODAY,
    },
    detection: {
      timeRange: {
        startEpoch: dayjs(currentDate).tz(tz).startOf('day').valueOf(),
        endEpoch: dayjs(currentDate).tz(tz).valueOf(),
      },
      timeRangeMode: TIME_RANGE_MODE.TODAY,
    },
  };
}

export function allowedIntervalsForRange(start: number, end: number): string[] {
  if (dayjs(end).diff(dayjs(start), 'minute') <= 30) {
    return [INTERVAL.MINUTE];
  }
  if (dayjs(end).diff(dayjs(start), 'hour') <= 1) {
    return [INTERVAL.MINUTE, INTERVAL.QUARTER_HOUR];
  }
  if (dayjs(end).diff(dayjs(start), 'hour') <= 2) {
    return [INTERVAL.MINUTE, INTERVAL.QUARTER_HOUR, INTERVAL.HALF_HOUR];
  }
  if (dayjs(end).diff(dayjs(start), 'hour') <= 24) {
    return [INTERVAL.MINUTE, INTERVAL.QUARTER_HOUR, INTERVAL.HALF_HOUR, INTERVAL.HOURLY];
  }
  if (dayjs(end).diff(dayjs(start), 'day') <= 7) {
    return [INTERVAL.HOURLY, INTERVAL.DAILY];
  }
  if (dayjs(end).diff(dayjs(start), 'day') >= 61) {
    return [INTERVAL.WEEKLY];
  }
  return [INTERVAL.DAILY];
}

const slice = createSlice({
  name: 'time',
  initialState: null as ITime | null,
  reducers: {
    updateTimeRange: (
      state: ITime | null,
      action: PayloadAction<{ range: IEpochRange; type: EDateRangePickerType }>,
    ) => {
      if (state) {
        if (action.payload.type === EDateRangePickerType.CHARTS) {
          const { chart } = state;
          const { startEpoch, endEpoch } = action.payload.range;
          const allowedIntervals = allowedIntervalsForRange(startEpoch, endEpoch);
          const interval = allowedIntervals.includes(state.interval)
            ? state.interval
            : allowedIntervals[0];
          return {
            ...state,
            interval,
            chart: {
              ...chart,
              timeRange: action.payload.range,
            },
          };
        }
        if (action.payload.type === EDateRangePickerType.DETECTION) {
          const { detection } = state;
          return {
            ...state,
            detection: {
              ...detection,
              timeRange: action.payload.range,
            },
          };
        }
      }
      return state;
    },

    updateTimezone: (state: ITime | null, action: PayloadAction<Pick<ITime, 'timezone'>>) => {
      return (
        state && {
          ...state,
          timezone: action.payload.timezone,
        }
      );
    },

    updateCurrent: (state: ITime | null, action: PayloadAction<Pick<ITime, 'currentDate'>>) => {
      return (
        state && {
          ...state,
          currentDate: action.payload.currentDate,
        }
      );
    },

    updateRangeMode: (
      state: ITime | null,
      {
        payload: { mode, type },
      }: PayloadAction<{ mode: TIME_RANGE_MODE; type: EDateRangePickerType }>,
    ) => {
      if (state) {
        if (type === EDateRangePickerType.CHARTS) {
          const { chart } = state;
          return {
            ...state,
            chart: {
              ...chart,
              timeRangeMode: mode,
            },
          };
        }
        if (type === EDateRangePickerType.DETECTION) {
          const { detection } = state;
          return {
            ...state,
            detection: {
              ...detection,
              timeRangeMode: mode,
            },
          };
        }
      }
      return state;
    },

    updateTime: (state: ITime | null, action: PayloadAction<ITime>) => {
      return action.payload;
    },

    updateTimeInterval: (state: ITime | null, action: PayloadAction<string>) => {
      return (
        state && {
          ...state,
          interval: action.payload,
        }
      );
    },
  },
});

const selectSelf = (state: RootState) => state.time;

export const selectTimeInterval = createSelector(selectSelf, (state) => state?.interval ?? '3600');

export const {
  updateTimeRange,
  updateRangeMode,
  updateTimezone,
  updateCurrent,
  updateTime,
  updateTimeInterval,
} = slice.actions;
export default slice.reducer;
