import { ApolloClient, InMemoryCache, createHttpLink, from, split } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { setContext } from '@apollo/client/link/context';
import { Observable, getMainDefinition } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { v4 as uuid } from 'uuid';
import { datadogRum } from '@datadog/browser-rum';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { isErrorNotAuthenticated } from '@/api/errors';
import { toast } from 'react-hot-toast';

if (!import.meta.env.VITE_GRAPHQL_BASE_URL) {
  console.warn('GraphQL base url not set');
}

if (!import.meta.env.VITE_WEB_GRAPHQL_SUBSCRIPTIONS_URL) {
  console.warn('WebGraphQL subscriptions url not set');
}

if (import.meta.env.DEV) {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

export function createApolloClient({ getToken }: { getToken: () => Promise<string> }) {
  const httpLink = createHttpLink({
    uri: import.meta.env.VITE_GRAPHQL_BASE_URL,
  });

  /**
   * Handles errors in the GraphQL request. Is also responsible for refreshing
   * the session and retrying the request if the session token has expired.
   */
  const errorLink = onError(({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      for (const gqlError of graphQLErrors) {
        const { message, locations, path, extensions } = gqlError;
        const code = extensions?.code;
        const requestId = extensions?.request_id;
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Request ID: ${requestId}`,
        );
        datadogRum.addError(gqlError, {
          request_id: requestId,
          code,
          source: operation.operationName,
        });
        // two ways we indicate the client is not authenticated
        if (code === 'UNAUTHENTICATED' || isErrorNotAuthenticated(gqlError)) {
          return new Observable((observer) => {
            getToken()
              .then((token) => {
                operation.setContext(({ headers = {} }) => ({
                  headers: {
                    // Re-add old headers
                    ...headers,
                    // Switch out old access token for new one
                    authorization: token ? `Bearer ${token}` : null,
                  },
                }));
              })
              .then(() => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };

                // Retry last failed request
                forward(operation).subscribe(subscriber);
              })
              .catch((error) => {
                // No refresh or client token available, we force user to login
                observer.error(error);
              });
          });
        } else {
          if (!operation.getContext().skipErrorToast) {
            toast.error(message);
          }
        }
      }
    }
  });

  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = await getToken();
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  const requestIdLink = setContext((operation, { headers }) => {
    // create a unique ID to trace this request
    const id = uuid();
    // log the request info with the ID so we can start tracing from
    // frontend logs
    datadogRum.addAction('graphQLRequest', {
      requestId: id,
      operationName: operation.operationName,
      variables: operation.variables,
    });
    return {
      headers: {
        ...headers,
        'X-Request-ID': id,
      },
    };
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: import.meta.env.VITE_WEB_GRAPHQL_SUBSCRIPTIONS_URL,
      shouldRetry: () => true,
      on: {
        error: (err) => {
          console.error(err);
        },
        closed: (ev) => {
          const closeEvent = ev as CloseEvent;
          if (closeEvent.code !== 1000 || !closeEvent.wasClean) {
            console.error(closeEvent);
          }
        },
      },
      connectionParams: async () => {
        const token = await getToken();
        return {
          authToken: token,
        };
      },
    }),
  );

  const webGraphglLink = createHttpLink({
    uri: import.meta.env.VITE_WEB_GRAPHQL_URL,
  });

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  const splitWsLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
    },
    wsLink,
    from([errorLink, httpLink]),
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'mutation' &&
        definition.name?.value === 'resetBackground'
      );
    },
    webGraphglLink,
    splitWsLink,
  );

  const client = new ApolloClient({
    link: requestIdLink.concat(authLink.concat(splitLink)),
    cache: new InMemoryCache(),
  });

  return client;
}

// add custom properties to operation context
declare module '@apollo/client' {
  export interface DefaultContext {
    skipErrorToast?: boolean;
  }
}
