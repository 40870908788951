// @ts-nocheck
import {} from '@types/pendo-io-browser';

declare global {
  interface Window {
    pendo: pendo.Pendo;
  }
}

/* istanbul ignore if */
/* c8 ignore next */
if (
  !import.meta.env.VITEST &&
  window.location.hostname !== 'localhost' &&
  import.meta.env.MODE !== 'test' &&
  import.meta.env.MODE !== 'development'
) {
  (function (apiKey) {
    (function (p, e, n, d, o) {
      let v, w, x, y, z;
      o = p[d] = p[d] || {};
      o._q = o._q || [];
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
      for (w = 0, x = v.length; w < x; ++w)
        (function (m) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
            };
        })(v[w]);
      y = e.createElement(n);
      y.async = !0;
      y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, 'script', 'pendo');
  })('ea8000ca-70eb-4044-6db1-f45019609283');
}

export {};
