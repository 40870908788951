import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/zh';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { ESQUnit, ELanguage } from '@/types';

dayjs.extend(localizedFormat);
dayjs.extend(timezone);

export const isDev = import.meta.env.VITE_DEV === 'true';

export const LOCAL_TZ = dayjs.tz.guess();

export const DEFAULT_LANG = ELanguage.EN_US;

export const DEFAULT_ORG_LOGO_NAME = 'org_default';

export const DT_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const T_FORMAT = 'HH:mm';

export const FONT_FAMILY_PER_LANG = {
  [ELanguage.EN_US]: 'Averta',
  [ELanguage.ZH_HANS]: 'OpenSans',
};

export const SQ_METRIC_TO_UNIT_CONVERSION_FEE = {
  [ESQUnit.METRIC]: 1,
  [ESQUnit.INCH]: 1550,
  [ESQUnit.FEET]: 10.7639,
};

// Fixed traffic windows in seconds
export const TRAFFIC_WINDOWS = [
  60 * 1,
  60 * 2,
  60 * 3,
  60 * 10,
  60 * 15,
  60 * 30,
  60 * 60,
  60 * 60 * 4,
  60 * 60 * 6,
  60 * 60 * 12,
  60 * 60 * 24,
  60 * 60 * 24 * 2,
  60 * 60 * 24 * 3,
  60 * 60 * 24 * 7,
  60 * 60 * 24 * 14,
  60 * 60 * 24 * 30,
];

// Fixed occupancy windows in seconds
export const OCCUPANCY_WINDOWS = [
  60 * 1,
  60 * 2,
  60 * 3,
  60 * 5,
  60 * 10,
  60 * 30,
  60 * 60,
  60 * 60 * 2,
  60 * 60 * 4,
  60 * 60 * 8,
  60 * 60 * 12,
  60 * 60 * 24,
  60 * 60 * 24 * 2,
  60 * 60 * 24 * 7,
];

export const TRAFFIC_MAX_DATA_COUNTS = 20;

export const OCCUPANCY_MAX_DATA_COUNTS = 200;

export const DETECTIONS_WINDOW = 20; // in seconds

export const DATE_TIME_FORMAT = {
  zh: {
    monthDate: 'MMMD日',
    hourMinute: 'Ah时mm分',
    hour: 'Ah时',
    fullDateTime: 'YYYY年MMMD日Ahh时mm分',
  },
  en: {
    monthDate: 'MMM D',
    hourMinute: 'h:mmA',
    hour: 'hA',
    fullDateTime: 'hh:mm A MMM D YYYY',
  },
};

export const DATE_FORMAT = 'YYYY-MM-DD';

export const DATE_ISO8601_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]';

export const ZERO_TIME = '0001-01-01T00:00:00Z';
