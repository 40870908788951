import { useCalendarGrid } from '@react-aria/calendar';
import { RangeCalendarState } from '@react-stately/calendar';
import {
  getWeeksInMonth,
  endOfMonth,
  DateDuration,
  startOfWeek,
  today,
} from '@internationalized/date';
import { useDateFormatter } from '@react-aria/i18n';
import { CalendarCell } from './CalendarCell';

interface ICalendarGrid {
  state: RangeCalendarState;
  offset?: DateDuration;
}

export function CalendarGrid({ state, offset = {} }: ICalendarGrid) {
  const startDate = state.visibleRange.start.add(offset);
  const endDate = endOfMonth(startDate);
  const { gridProps, headerProps } = useCalendarGrid(
    {
      startDate,
      endDate,
    },
    state,
  );
  const dayFormatter = useDateFormatter({
    weekday: 'short',
    timeZone: state.timeZone,
  });
  const weekStart = startOfWeek(today(state.timeZone), 'en-US');
  const weekDays = [...new Array(7).keys()].map((index) => {
    const date = weekStart.add({
      days: index,
    });
    const dateDay = date.toDate(state.timeZone);
    return dayFormatter.format(dateDay).toUpperCase();
  });

  // Get the number of weeks in the month so we can render the proper number of rows.
  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, 'en-US');
  return (
    <table {...gridProps}>
      <thead {...headerProps}>
        <tr>
          {weekDays.map((day, index) => (
            <th className="font-semibold text-gray-900" key={index}>
              {day}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
          <tr key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex, startDate)
              .map((date, i) =>
                date ? (
                  <CalendarCell
                    key={i}
                    state={state}
                    date={date}
                    currentMonth={startDate}
                    weekCount={weeksInMonth}
                  />
                ) : (
                  <td key={i} />
                ),
              )}
          </tr>
        ))}
      </tbody>
    </table>
  );
}
